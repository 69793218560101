import React, { createContext, useContext, useState } from 'react';
import { useIntl } from 'react-intl';

const ShippingsUIContext = createContext();
export const useShippingsUIContext = () => useContext(ShippingsUIContext)


export const ShippingsUIProvider = ({ children }) => {

  const { formatMessage: intl } = useIntl();

  const [ids, setIds] = useState([]);

  // Shipping
  const [showPrintLabel, setShowPrintLabel] = useState(false);
  const [shipperName, setShipperName] = useState(null);
  const openPrintLabelsDialog = (service) => {
    setShipperName(service);
    setShowPrintLabel(true);
  };
  const closePrintLabelsDialog = () => {
    setShipperName(null);
    setShowPrintLabel(false);
  };

  // Shipping delete modal
  const [showDeleteLabel, setShowDeleteLabel] = useState(false);
  const [deleteLabelParams, setDeleteLabelParams] = useState(null);
  const openDeleteLabelDialog = (params) => {
    setShowDeleteLabel(true);
    setDeleteLabelParams(params);
  };
  const closeDeleteLabelDialog = () => {
    setShowDeleteLabel(false);
    setDeleteLabelParams(null)
  };

  const initialDateRange = {
    id: "clear-date",
    label: intl({ id: "GENERAL.CLEAR" }),
  };

  const [dateRange, setDateRange] = useState(
    initialDateRange
  );
  const changeDateRange = (date = initialDateRange) => setDateRange(date);

  const value = {
    ids,
    setIds,

    shipperName,
    showPrintLabel,
    openPrintLabelsDialog,
    closePrintLabelsDialog,

    openDeleteLabelDialog,
    closeDeleteLabelDialog,
    showDeleteLabel,
    deleteLabelParams,

    setDateRange,
    dateRange,
    changeDateRange,
    initialDateRange,
  };

  return (
    <ShippingsUIContext.Provider value={value}>
      {children}
    </ShippingsUIContext.Provider>
  );
};
