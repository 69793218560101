import React, { useEffect, useMemo, useState } from 'react';
import { SearchControl } from '_metronic/_partials/controls';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useCustomersUIContext } from '../_context/CustomersUIContext';
import { DatePickerDropdown } from '_metronic/_partials/components';
import { CONNECTION_SETTINGS } from 'constants/connectionSettings';
import { MultipleFilter } from '_metronic/_partials/components';
import { CustomersGrouping } from './CustomersGrouping';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { SelectCounter } from '_metronic/_partials';
import { Col, Row } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import { useQuery } from 'app/hooks';



export function CustomersFilter({ queryParams, setSearchQuery, setFilterQuery, marketFilterContent }) {

  const query = useQuery();
  const [search, setSearchBase] = useState(query.get('search') ?? '');

  const UIContext = useCustomersUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    openExportCustomerDialog: UIContext.openExportCustomerDialog,
  }), [UIContext]);

  useEffect(() => {
    setSearchBase(query.get('search') ?? '');
  }, [query]);

  const handleSearch = value => {
    setSearchQuery(value);
    setSearchBase(value);
  };

  // Market Filter Fonksiyonları - MultipleFilter
  // Salevali ayrı filtre olarak gösterilmeyecek, Non-market seçeneği ile gösterilecek
  const existingMarkets = CONNECTION_SETTINGS.filter(setting => marketFilterContent?.includes(setting.name) && setting.name !== "salevali");

  existingMarkets.push({
    name: "non_market",
    label: <FormattedMessage id="GENERAL.FILTER.NONE_MARKET" />,
    icon: <Icon.ShopWindow className="text-dark-75" />
  })

  const defaultMarketFilterValues = useMemo(() => {
    const values = {};
    existingMarkets.forEach(entity => {
      values[entity.name] = queryParams?.filter?.marketplaces?.[entity.name] ? true : false;
    });
    return values;
  }, [existingMarkets, queryParams]);

  const [marketFilterValue, setMarketFilterValue] = useState(defaultMarketFilterValues);

  const onFilterConnectionHandler = (value) => {
    setMarketFilterValue(value);
    setFilterQuery(value, "marketplaces");
  }


  return (
    <Row className="mb-3">

      <Col md="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col md="8" className='d-flex align-items-start px-md-0'>

        <MultipleFilter
          icon={<Icon.Shop />}
          title={<FormattedMessage id='DOCUMENT.FILTER.MARKET' />}
          items={existingMarkets}
          itemName={<FormattedMessage id="GENERAL.FILTER.MARKET_SINGULAR" />}
          noItemMessage={<FormattedHTMLMessage id="DOCUMENT.SHIPPING.NO_MARKETPLACE_FILTER" values={{ url: ModuleRoutes.CONNECTIONS, a: chunks => <a href='/'>{chunks}</a> }} />}
          selectedFilters={marketFilterValue}
          onFilterChange={onFilterConnectionHandler}
        />

        <DatePickerDropdown UIContext={UIContext} setFilterQuery={setFilterQuery} />

        <SelectCounter count={UIProps.ids.length} />

        <CustomersGrouping UIProps={UIProps} />
      </Col>

    </Row>
  );
}
