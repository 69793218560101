import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls';
import { CounterPriceCard, LineChartDailySales, OrderByMarketplace, TableTopCustomer, TableTopProduct } from './widgets'
import { useDashboardUIContext } from './_context/DashboardUIContext';
//import { DatePickerDropdown } from '_metronic/_partials/components';
import { UserGuideHelper } from '../../user-guides/UserGuideHelper';
import { LoadingDialog } from "_metronic/_partials/loading";
import { DashboardActions } from './_redux/actions'
import { Icon } from '_metronic/_icons';



export default function DashboardCard() {

  const dispatch = useDispatch();

  const UIContext = useDashboardUIContext();
  const UIProps = useMemo(() => ({
    dateRange: UIContext.dateRange,
  }), [UIContext]);

  const { loading, country, daily_sales, marketplace, open_invoices, orders, sales, top_costumer, top_product } = useSelector(state => state.dashboard)

  useEffect(() => {
    UIProps.dateRange.length !== 0 && dispatch(DashboardActions.getDashboard(UIProps.dateRange));
  }, [dispatch, UIProps.dateRange]);

  return (
    <Card className="card-box">

      <CardHeader
        icon={<Icon.Dashboard />}
        title={<FormattedMessage id="DASHBOARD.TITLE" />}
        info={<UserGuideHelper />}
        className="display-5"
        sticky
      >
        <CardHeaderToolbar>
          {/*<DatePickerDropdown UIContext={UIContext} />*/}
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody>
        <Row>

          <Col lg='12' xl='4'>
            <CounterPriceCard title="DASHBOARD.ORDERS" data={orders} dateRange={UIProps.dateRange} />
          </Col>

          <Col lg='12' xl='4'>
            <CounterPriceCard title="DASHBOARD.SALES" data={sales} dateRange={UIProps.dateRange} />
          </Col>

          <Col lg='12' xl='4'>
            <CounterPriceCard title="DASHBOARD.OPEN_INVOICES" data={open_invoices} dateRange={UIProps.dateRange} />
          </Col>

          <Col xl='12' className='col-xxl-6'>
            <OrderByMarketplace title='DASHBOARD.SALES_BY_CHANNEL' data={marketplace} toggleState="sales_by_channel" />
          </Col>

          <Col xl='12' className='col-xxl-6'>
            <OrderByMarketplace title='DASHBOARD.SALES_BY_COUNTRY' data={country} toggleState="sales_by_country" />
          </Col>

          <Col xs="12">
            <LineChartDailySales data={daily_sales} />
          </Col>

          <Col xl='12' className='col-xxl-6'>
            <TableTopProduct data={top_product} />
          </Col>

          <Col xl='12' className='col-xxl-6'>
            <TableTopCustomer data={top_costumer} />
          </Col>

        </Row>
      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  );
}