import React, { useState, createContext, useContext } from 'react';
import { useIntl } from 'react-intl';


const ProductsUIContext = createContext();
export const ProductsUIConsumer = ProductsUIContext.Consumer;
export const useProductsUIContext = () => useContext(ProductsUIContext);


export function ProductsUIProvider({ children }) {

  const { formatMessage: intl } = useIntl();

  const [ids, setIds] = useState([]);

  //* Product details dialog
  const [productId, setProductId] = useState()
  const [showProductDetailsDialog, setShowProductDetailsDialog] = useState(false);
  const openProductDetailsDialog = id => {
    setProductId(id);
    setShowProductDetailsDialog(true);
  };
  const closeProductDetailsDialog = () => {
    setProductId(undefined);
    setShowProductDetailsDialog(false);
  };

  // Export
  const [showExportProductDialog, setShowExportProductDialog] = useState(false);
  const openExportProductDialog = (id) => {
    setShowExportProductDialog(true);
    setProductId(id);
  };
  const closeExportProductDialog = () => {
    setShowExportProductDialog(false)
    setProductId(undefined);
  };

  const [nettoBruttoPrice, setNettoBruttoPrice] = useState('price_is_netto');

  const [showProductLoadingDialog, setShowProductLoadingDialog] = useState(false);

  const handleCloseProductLoadingDialog = () => setShowProductLoadingDialog(false);

  const initialDateRange = {
    id: "clear-date",
    label: intl({ id: "GENERAL.CLEAR" }),
  };

  const [dateRange, setDateRange] = useState(
    initialDateRange
  );
  const changeDateRange = (date = initialDateRange) => setDateRange(date);

  const value = {
    ids,
    setIds,
    showProductLoadingDialog,
    setShowProductLoadingDialog,
    handleCloseProductLoadingDialog,

    // openShopsDialog: ids => history.push(ModuleRoutes.UPDATE_SHOPS_QUANTITY_FN(ids)),
    nettoBruttoPrice,
    setNettoBruttoPrice,

    productId,
    showProductDetailsDialog,
    closeProductDetailsDialog,
    openProductDetailsDialog,

    showExportProductDialog,
    openExportProductDialog,
    closeExportProductDialog,

    changeDateRange,
    dateRange,
    setDateRange,
    initialDateRange,
  };

  return (
    <ProductsUIContext.Provider value={value}>
      {children}
    </ProductsUIContext.Provider>
  );
}
