import React from 'react';
import { Prompt } from 'react-router-dom';
import { Formik, Form, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';



export function FormikHandler({ initialValues, btnRef, onSubmitHandler, children, autoComplete = 'off' }) {

  const { formatMessage: intl } = useIntl();

  const PromptIfDirty = () => {
    const formik = useFormikContext();
    return (
      <Prompt
        when={formik.dirty && formik.submitCount === 0}
        message={location => intl({ id: 'PRODUCT_LISTINGS.PROMPT_MESSAGE' })}
      />
    );
  };


  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(80, intl({ id: 'PRODUCT_LISTINGS.ARTIKEL_NAME_MAX' }))
      .required(intl({ id: 'GENERAL.REQUIRED' })),
    id_shipping: Yup.string()
      .required(intl({ id: 'GENERAL.REQUIRED' })),
    id_warehouse: Yup.string()
      .required(intl({ id: 'GENERAL.REQUIRED' })),
  });


  return (
    <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitHandler}>
      {({ handleSubmit }) => (
        <Form className="form form-label-right" autoComplete={autoComplete}>
          <PromptIfDirty />
          {children}
          <button type="submit" className='d-none' ref={btnRef} />
        </Form>
      )}
    </Formik>
  );
}
