import React, { useEffect, useState } from 'react';
import { useOrderListUIContext } from '../../_context/OrderListUIContext';
import { DatePickerDropdown } from '_metronic/_partials/components';
import { SearchControl } from '_metronic/_partials/controls';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'app/hooks';



export function OrderListFilter({ UIProps }) {

  const query = useQuery();
  const [search, setSearch] = useState(query.get('search') ?? '');

  const UIContext = useOrderListUIContext();

  useEffect(() => {
    setSearch(query.get('search') ?? '');
  }, [query]);

  const handleSearch = (values) => {
    setSearch(values);
    UIProps.setSearchQuery(values);
  }


  return (
    <Row className="mb-3">

      <Col sm="12" md="6" lg="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col sm="12" md="6" lg="8" className='d-flex align-items-start px-md-0'>
        <DatePickerDropdown UIContext={UIContext} setFilterQuery={UIProps.setFilterQuery} />
      </Col>

    </Row>
  );
}
