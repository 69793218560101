import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UserGuideHelper } from 'app/user-guides/UserGuideHelper';
import { Card, CardBody, CardHeader } from "_metronic/_partials/controls";
import { BlankMessage } from '_metronic/_partials/components';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { Icon } from '_metronic/_icons';



export function ProductListingsPage() {

  return (
    <Card className="card-height">

      <CardHeader
        icon={<Icon.Intersect />}
        title={<FormattedMessage id="MENU.ASIDE.LISTINGS" />}
        info={<UserGuideHelper />}
        className="display-5"
        id='title_listings'
      >
      </CardHeader>

      <CardBody>
        <BlankMessage
          icon={<Icon.Intersect />}
          title={<FormattedMessage id='CONNECTIONS.BLANK_MESSAGE_TITLE' />}
          message={<FormattedMessage id='CONNECTIONS.BLANK_MESSAGE' />}
          button={{ link: ModuleRoutes.CONNECTIONS, label: "CONNECTIONS.ADD_CONNECTIONS" }}
          className='min-50-height'
        />
      </CardBody>

    </Card>
  )
}
