import React, { useEffect, useState } from 'react';
import { MODULE_TRANSLATIONS, RESULTS_TRANSLATIONS } from '../../_context/UserUIHelpers';
import { DatePickerDropdown } from '_metronic/_partials/components';
import { useUserUIContext } from '../../_context/UserUIContext';
import { SearchControl } from '_metronic/_partials/controls';
import { Dropdown, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { theme } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import { useQuery } from 'app/hooks';



export function LogsFilter({ queryParams, setSearchQuery, setFilterQuery }) {

  const UIContext = useUserUIContext();

  const query = useQuery();
  const [search, setSearchBase] = useState(query.get('search') ?? '');

  // Results Filter state
  const [resultsFilterValue, setResultsFilterValue] = useState({
    error_filter: queryParams?.filter?.error || false,
    success_filter: queryParams?.filter?.success || false,
  });

  // Type Filter state
  const [moduleFilterValue, setModuleFilterValue] = useState({
    archive: queryParams?.filter?.archive || false,
    category: queryParams?.filter?.category || false,
    company: queryParams?.filter?.company || false,
    connection: queryParams?.filter?.connection || false,
    customer: queryParams?.filter?.customer || false,

    fulfillment: queryParams?.filter?.fulfillment || false,
    credit: queryParams?.filter?.credit || false,
    invoice: queryParams?.filter?.invoice || false,
    offer: queryParams?.filter?.offer || false,
    order: queryParams?.filter?.order || false,
    payment: queryParams?.filter?.payment || false,
    waybill: queryParams?.filter?.waybill || false,

    dashboard: queryParams?.filter?.dashboard || false,
    export: queryParams?.filter?.export || false,

    amazon: queryParams?.filter?.amazon || false,
    ebay: queryParams?.filter?.ebay || false,
    kaufland: queryParams?.filter?.kaufland || false,

    product: queryParams?.filter?.product || false,
    digital_product: queryParams?.filter?.digital_product || false,

    shipping: queryParams?.filter?.shipping || false,
    file: queryParams?.filter?.file || false, //remotes
    supplier: queryParams?.filter?.supplier || false,
    support: queryParams?.filter?.support || false,
    user: queryParams?.filter?.user || false,
  });

  const handleResultsFilterChange = (e) => {
    const { name, checked } = e.target;

    // Güncel filtre değerlerini ayarlama
    setResultsFilterValue((prev) => {
      const updatedFilterValue = { ...prev, [name]: checked };

      // Bir durum seçildiğinde diğerinin otomatik olarak kapatılması
      if (name === "success_filter" && checked) {
        updatedFilterValue.error_filter = false;
      } else if (name === "error_filter" && checked) {
        updatedFilterValue.success_filter = false;
      }
      return updatedFilterValue;
    });

    // Filtreyi güncelle
    setFilterQuery(name, checked);
  };

  const handleModuleFilterChange = (e) => {
    const { name, checked } = e.target;
    // Güncel filtre değerlerini ayarlama
    setModuleFilterValue((prev) => ({ ...prev, [name]: checked }));
    // Filtreyi güncelle
    setFilterQuery(name, checked);
  };

  const resetResultsFilters = () => {
    // Tüm filtreleri sıfırlama
    setResultsFilterValue({
      error_filter: false,
      success_filter: false,
    });

    // Tüm filtreleri kaldır
    setFilterQuery("DELETE_PARAMS", ["error_filter", "success_filter"]);
  };

  const resetModuleFilters = () => {
    // Tüm filtreleri sıfırlama
    setModuleFilterValue({
      archive: false,
      category: false,
      company: false,
      connection: false,
      customer: false,

      fulfillment: false,
      credit: false,
      invoice: false,
      offer: false,
      order: false,
      payment: false,
      waybill: false,

      dashboard: false,
      export: false,

      amazon: false,
      ebay: false,
      kaufland: false,

      product: false,
      digital_product: false,

      shipping: false,
      file: false,
      supplier: false,
      support: false,
      user: false,
    });

    // Tüm filtreleri kaldır
    setFilterQuery("DELETE_PARAMS", ["archive", "category", "company", "connection", "customer", "fulfillment", "credit", "invoice", "offer", "order", "payment", "waybill", "dashboard", "export", "amazon", "ebay", "kaufland", "product", "digital_product", "shipping", "file", "supplier", "support", "user"]
    );
  };

  useEffect(() => {
    setSearchBase(query.get('search') ?? '');
  }, [query]);

  const handleSearch = (value) => {
    setSearchQuery(value);
    setSearchBase(value);
  };


  return (
    <Row className='mb-3'>
      <Col sm="12" md="6" lg="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col sm="12" md="6" lg="8" className='d-flex align-items-start px-md-0'>


        {/* --------------------------------- Result Filter --------------------------------- */}
        <Dropdown className="dropdown-inline symbol mr-3" drop="down">

          <Dropdown.Toggle variant='outline-secondary' className="svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <Icon.Filter className="mr-3" />
            <FormattedMessage id='ADMIN.USER.LOGS.RESULTS_FILTER.FILTER_TITLE' />
          </Dropdown.Toggle>

          {Object.keys(resultsFilterValue).some(key => resultsFilterValue[key]) && <i className="symbol-badge bg-danger"></i>}
          <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim-down" style={{ minWidth: '180px' }} id="dropdown_results_filter">
            <ul className="navi navi-hover font-weight-bold">
              {Object.keys(resultsFilterValue).map((filter) => (
                <li className="navi-item" key={filter}>
                  <label className="navi-link checkbox checkbox-primary">
                    <input type="checkbox" name={filter} checked={resultsFilterValue[filter]} onChange={handleResultsFilterChange} />
                    <span />
                    <div className="text-nowrap ml-1">
                      {RESULTS_TRANSLATIONS[filter.toLowerCase()]}
                    </div>
                  </label>
                </li>
              ))}

              <Dropdown.Divider />

              <li className="navi-item">
                <Dropdown.Item className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1' id='clear'>
                  <span onClick={resetResultsFilters}>
                    <FormattedMessage id='GENERAL.CLEAR' />
                  </span>
                </Dropdown.Item>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>


        {/* --------------------------------- Module Filter --------------------------------- */}
        <Dropdown className="dropdown-inline symbol mr-3" drop="down">

          <Dropdown.Toggle variant='outline-secondary' className="svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <Icon.Sliders className="mr-3" />
            <FormattedMessage id='ADMIN.USER.LOGS.MODULES_FILTER.FILTER_TITLE' />
          </Dropdown.Toggle>

          {Object.keys(moduleFilterValue).some(key => moduleFilterValue[key]) && <i className="symbol-badge bg-danger"></i>}
          <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim-down" style={{ minWidth: '180px' }} id="dropdown_type_filter">
            <ul className="navi navi-hover font-weight-bold">
              {Object.keys(moduleFilterValue).map((filter) => (
                <li className="navi-item" key={filter}>
                  <label className="navi-link checkbox checkbox-primary">
                    <input type="checkbox" name={filter} checked={moduleFilterValue[filter]} onChange={handleModuleFilterChange} />
                    <span />
                    <div className="text-nowrap ml-1">
                      {MODULE_TRANSLATIONS[filter.toLowerCase()]}
                    </div>
                  </label>
                </li>
              ))}

              <Dropdown.Divider />

              <li className="navi-item">
                <Dropdown.Item className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1' id='clear'>
                  <span onClick={resetModuleFilters}>
                    <FormattedMessage id='GENERAL.CLEAR' />
                  </span>
                </Dropdown.Item>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>


        {/* --------------------------------- Date Filter --------------------------------- */}
        <DatePickerDropdown UIContext={UIContext} setFilterQuery={setFilterQuery} />

      </Col>
    </Row>
  );
}
