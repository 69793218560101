import React, { useEffect, useState } from 'react';
import { EXPORT_STATUS, IMPORT_STATUS, IMPORT_TITLE } from '../../_context/ImportExportUIHelper';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { theme } from '_metronic/_helpers';
import { useQuery } from 'app/hooks';



export function ImportFilter({ UIProps }) {

  const query = useQuery();
  const [filterParamsBase, setFilterParamsBase] = useState({
    data_type: 'all_types',
  });

  useEffect(() => {
    const data_type = query.get('filter.data_type');

    setFilterParamsBase(prev => ({
      ...prev,
      data_type: IMPORT_STATUS.map(item => item.module).includes(data_type) ? data_type : 'all_types',
    }));

  }, [query])

  const onFilterTypeHandler = (value) => {
    UIProps.setFilterQuery('data_type', value)
    setFilterParamsBase(prev => ({
      ...prev,
      data_type: value
    }))
  }

  const onDeleteFilterHandler = (key, value) => {
    setFilterParamsBase(prev => ({
      ...prev,
      [key]: 'all_types',
    }));
    UIProps.setFilterQuery('DELETE_PARAMS', key);
  }


  return (
    <Row >
      {/* <Col md="4">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col> */}

      <Col sm="12" md="6" lg="8" className='d-flex align-items-start justify-content-start px-md-0'>


        {/* --------------------------------------- Export-Type filter --------------------------------------- */}
        <Dropdown className="dropdown-inline symbol mr-3" drop="down">

          <Dropdown.Toggle variant="outline-secondary" className='d-flex justify-content-between align-items-center font-weight-bold svg-icon svg-icon-sm' style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            {IMPORT_STATUS.find(i => i.module === filterParamsBase.data_type)?.icon}
            <span className="d-none d-sm-inline ml-2">
              <FormattedMessage id={EXPORT_STATUS.find(i => i.module === filterParamsBase.data_type)?.label} />
            </span>
          </Dropdown.Toggle>

          {filterParamsBase.data_type !== 'all_types' && <i className="symbol-badge bg-danger"></i>}
          <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim-down" id="dropdown_export_type_options">
            <ul className="navi navi-hover">

              {IMPORT_TITLE.map((item) => (
                <li key={item.module} className="navi-item">
                  <Dropdown.Item className='navi-link font-weight-bold' id={`dropdown_item_${item.module}`}
                    onClick={() => onFilterTypeHandler(item.module)}>
                    <span className="svg-icon svg-icon-md mr-2">
                      {item.icon}
                    </span>
                    <span className="navi-text">
                      <FormattedMessage id={item.label} />
                    </span>
                  </Dropdown.Item>
                </li>
              ))}
              <Dropdown.Divider />

              <li className="navi-item">
                <Dropdown.Item
                  className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1'
                  onClick={() => onDeleteFilterHandler('data_type', IMPORT_STATUS.find(i => i.module === filterParamsBase.data_type)?.module)}
                  id='clear-export-type'
                >
                  <FormattedMessage id='GENERAL.CLEAR' />
                </Dropdown.Item>
              </li>

            </ul>
          </Dropdown.Menu>
        </Dropdown>

      </Col>
    </Row>
  );
}
