import React, { useEffect, useState } from 'react';
import { DatePickerDropdown } from '_metronic/_partials/components';
import { useArchiveUIContext } from '../_context/ArchiveUIContext';
import { SearchControl } from '_metronic/_partials/controls';
import { Col, Dropdown, Row } from 'react-bootstrap';
import * as uiHelpers from "../_context/ArchiveUIHelpers";
import { SelectCounter } from '_metronic/_partials';
import { ArchiveGrouping } from './ArchiveGrouping';
import { theme } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import { useQuery } from 'app/hooks';



export function ArchiveFilter({ setSearchQuery, UIProps, setFilterQuery, queryParams, totalDocuments }) {

  const query = useQuery();
  const [search, setSearchBase] = useState(query.get('search') ?? '');

  const UIContext = useArchiveUIContext();
  useEffect(() => {
    setSearchBase(query.get('search') ?? '');
  }, [query]);

  const handleSearch = (value) => {
    setSearchQuery(value);
    setSearchBase(value);
  };

  const onFilterHandler = (value) => {
    UIProps.setRequestedArchive(value)
    setFilterQuery('doc_type', value);
  };


  return (
    <Row className="mb-3">

      <Col sm="12" md="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col sm="12" md="8" className='d-flex align-items-start px-md-0'>

        <Dropdown className="dropdown-inline mr-3" drop="down">

          <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <Icon.Archive className="mr-3" />
            {uiHelpers.DOCUMENTS_OPTIONS.find(i => i.value === UIProps.requestedArchive.value)?.label}
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu dropdown-menu-right dropdown-menu-anim-up" id='dropdown_date_grouping'>
            <ul className="navi navi-hover">
              {uiHelpers.DOCUMENTS_OPTIONS.map((filter, index) => (
                <li className="navi-item" key={index}>
                  <Dropdown.Item className='navi-link font-weight-bold' onClick={() => onFilterHandler(filter.value)} id={`dropdown_item_${filter.value}`}>
                    <Icon.Archive className="mr-1" />
                    <span className="navi-text">
                      {filter.label}
                    </span>
                  </Dropdown.Item>
                </li>
              ))}
            </ul>
          </Dropdown.Menu>

        </Dropdown>

        <DatePickerDropdown UIContext={UIContext} setFilterQuery={setFilterQuery} />

        <SelectCounter count={UIContext.ids.length} />

        {totalDocuments > 0 && <ArchiveGrouping />}
      </Col>

    </Row>
  );
}