import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isFunction, isEmpty, isArray } from 'lodash';
import { useIntl } from 'react-intl';


const CustomersUIContext = createContext();
export const CustomersUIConsumer = CustomersUIContext.Consumer;
export const useCustomersUIContext = () => useContext(CustomersUIContext)



export function CustomersUIProvider({ children }) {

  const { formatMessage: intl } = useIntl();

  const { customerForEdit } = useSelector(state => ({
    customerForEdit: state.customers.customerForEdit,
  }));

  const initialAddress = {
    type: 'address_delivery',
    salutation: '',
    name: customerForEdit.name,
    company: customerForEdit.company,
    address_line1: '',
    address_line2: '',
    post_code: '',
    city: '',
    country_code: 'DE',
  };


  // ------------------------------ Address ------------------------------ //


  useEffect(() => {
    if (!isEmpty(customerForEdit) && isArray(customerForEdit.address) && customerForEdit.address.length > 0) {
      return setAddress(customerForEdit.address);
    }
    return setAddress([]);
  }, [customerForEdit]);


  const [ids, setIds] = useState([]);

  const [showCustomerLoadingDialog, setShowCustomerLoadingDialog] = useState(false);
  const handleCloseCustomerLoadingDialog = () => {
    setShowCustomerLoadingDialog(false);
  };

  const [showServerRespondDialog, setShowServerRespondDialog] = useState(false);
  const handleCloseServerRespondDialog = () => {
    setShowServerRespondDialog(false);
  };
  const handleOpenServerRespondDialog = () => {
    setShowServerRespondDialog(true);
  };


  const [address, setAddress] = useState([]);
  const [addressForEdit, setAddressForEdit] = useState({});
  const addAddressHandler = newAddress => {
    setAddress(prev => [...prev, newAddress]);
  };
  const updateAddressHandler = updatedAddress => {
    setAddress(prev => {
      const findAddressIndex = prev.findIndex(add => add.id === updatedAddress.id);
      if (findAddressIndex !== -1) {
        prev[findAddressIndex] = updatedAddress;
      }
      return prev;
    });
  };
  const getAddressForEditHandler = id => {
    const getAddress = address.find(add => add.id === id);
    setAddressForEdit(getAddress ? getAddress : {});
  };
  const deleteAddressHandler = id => {
    setAddress(prev => prev.filter(add => add.id !== id));
  };


  const [customerId, setCustomerId] = useState();
  const [showEditAddressDialog, setShowEditAddressDialog] = useState(false);
  const openEditAddressDialogHandler = id => {
    setCustomerId(id);
    setShowEditAddressDialog(true);
  };
  const closeEditAddressDialogHandler = () => {
    setCustomerId(undefined);
    setShowEditAddressDialog(false);
  };


  // Hidden Inputs if we need
  const [hiddenInputs, setHiddenInputsBase] = useState([]);
  const setHiddenInputs = useCallback(nextHiddenInputs => {
    if (isFunction(nextHiddenInputs)) {
      return setHiddenInputsBase(nextHiddenInputs);
    }
    setHiddenInputsBase([]);
  }, []);

  //clean up context
  const cleanUpContextHandler = () => {
    setCustomerId(undefined);
    setIds([]);
    setAddress([]);
    setAddressForEdit({});
  };

  //* Customer details dialog
  const [showCustomerDetailsDialog, setShowCustomerDetailsDialog] = useState(false);
  const openCustomerDetailsDialog = id => {
    setCustomerId(id);
    setShowCustomerDetailsDialog(true);
  };
  const closeCustomerDetailsDialog = () => {
    setCustomerId(undefined);
    setShowCustomerDetailsDialog(false);
  };


  // Export
  const [showExportCustomerDialog, setShowExportCustomerDialog] = useState(false);
  const openExportCustomerDialog = (id) => {
    setShowExportCustomerDialog(true);
    setCustomerId(id);
  };
  const closeExportCustomerDialog = () => {
    setShowExportCustomerDialog(false)
    setCustomerId(undefined);
  };

  const initialDateRange = {
    id: "clear-date",
    label: intl({ id: "GENERAL.CLEAR" }),
  };

  const [dateRange, setDateRange] = useState(
    initialDateRange
  );
  const changeDateRange = (date = initialDateRange) => setDateRange(date);

  const value = {
    cleanUpContextHandler,
    hiddenInputs,
    setHiddenInputs,
    ids,
    setIds,

    initialAddress,
    address,
    addressForEdit,
    addAddressHandler,
    updateAddressHandler,
    getAddressForEditHandler,
    deleteAddressHandler,

    customerId,
    showEditAddressDialog,
    openEditAddressDialogHandler,
    closeEditAddressDialogHandler,

    showCustomerLoadingDialog,
    handleCloseCustomerLoadingDialog,
    handleOpenServerRespondDialog,
    showServerRespondDialog,
    handleCloseServerRespondDialog,
    setShowCustomerLoadingDialog,

    showCustomerDetailsDialog,
    closeCustomerDetailsDialog,
    openCustomerDetailsDialog,

    showExportCustomerDialog,
    openExportCustomerDialog,
    closeExportCustomerDialog,

    setDateRange,
    dateRange,
    changeDateRange,
    initialDateRange,

  };

  return (
    <CustomersUIContext.Provider value={value}>
      {children}
    </CustomersUIContext.Provider>
  );
}
