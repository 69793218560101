import React, { useEffect, useState, useRef, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useProductListingsUIContext } from '../../_context/ProductListingsUIContext';
import { EditErrorDialog } from '../../ebay-listings/ebay-listings-edit/EditErrorDialog';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls';
import KauflandCategory from './categories/KauflandCategory';
import { BackButton, SaveButton } from '_metronic/_partials';
import KauflandPolicies from './policies/KauflandPolicies';
import KauflandVariant from './variants/KauflandVariant';
import KauflandGeneral from './general/KauflandGeneral';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { ListingActions } from '../../_redux/actions';
import { getLastSearch } from '_metronic/_helpers';
import { FormikHandler } from './FormikHandler';
import { FormattedMessage } from 'react-intl';
import { useLoadingHandler } from 'app/hooks';
import { toast } from 'react-toastify';



export function KauflandListingsEdit({ history, match: { params: { id, market_id, method } } }) {

  const dispatch = useDispatch()
  const lastSearch = getLastSearch()

  const UIContext = useProductListingsUIContext();
  const UIProps = useMemo(() => ({
    categoryInputValue: UIContext.categoryInputValue,
    selectedCategoryAspects: UIContext.selectedCategoryAspects,
  }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [UIContext.categoryInputValue, UIContext.selectedCategoryAspects]
  );
  const { productForEdit, isLoading, categorySuggestions, shippingGroups, warehouses, actionTracker } = useSelector(
    state => ({
      productForEdit: state.listings.productForEdit,
      isLoading: state.listings.isLoading,
      categorySuggestions: state.listings.categorySuggestions,
      shippingGroups: state.listings.shippingGroups,
      actionTracker: state.listings.actionTracker,
      warehouses: state.listings.warehouses,
    }),
    shallowEqual
  );

  const [openErrorDialog, setOpenErrorDialog] = useState({ show: false, errors: [] });
  const [isVariant, setIsVariants] = useState([]);
  const closeErrorDialog = () => setOpenErrorDialog({ show: false, errors: [] });

  useEffect(() => {
    if (id && market_id) {
      dispatch(ListingActions.kauflandInventoryListingsPreparationRequest(id, market_id, method));
    }
  }, [id, dispatch, market_id, method]);

  // useEffect(() => {
  //   if (method === 'new' && productForEdit.name) {
  //     dispatch(ListingActions.ebayInventoryListingsCategory(market_id, productForEdit.name));
  //   }
  //   const listing = productForEdit?.ebayInventory;
  //   if (method === 'edit') {
  //     if (listing && listing?.category?.id !== "" && listing?.category?.path === "" && listing?.category?.name === "") {
  //       dispatch(ListingActions.ebayInventoryListingsCategory(market_id, productForEdit.name));
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [productForEdit?.name, id, dispatch, market_id, method]);


  const btnRef = useRef();
  const saveClickButton = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const handleDeletedRowChange = (deletedRow) => setIsVariants(deletedRow);
  const loading = useLoadingHandler(isLoading, ListingActions.cleanUpReducer());

  const onSubmitHandler = values => {

    if (!values?.identities?.ean) {
      return toast.warning(<FormattedMessage id="PRODUCT_LISTINGS.EAN_MESSAGE" />);
    }

    const hasRequiredAspects = UIProps.selectedCategoryAspects.filter(asp => asp.isRequired && asp.value === '');
    if (hasRequiredAspects.length > 0) {
      toast.error(<FormattedMessage id="PRODUCT_LISTINGS.EBAY.REQUIRED_ASPECTS" />);
      return;
    }
    let aspects = {};
    for (const aspect of UIProps.selectedCategoryAspects.filter(asp => asp.value !== '')) {
      aspects = { ...aspects, [aspect.name.toString()]: aspect.value };
    };

    values.variants = values.variants.filter(variant => !isVariant.includes(variant.id));


    // let aspects = [];
    // for (const aspect of selectedCategoryAspects) {
    //   if (aspect.value) {
    //     aspects.push({ name: aspect.name, value: aspect.value });
    //   } else {
    //     const findCategory = categorySuggestions.find(
    //       cs => cs.id_category === categoryInputValue.value
    //     );
    //     if (findCategory) {
    //       const findAspect = findCategory.required_attributes.find(
    //         x => x.title === aspect.name
    //       );
    //       if (findAspect) {
    //         return toast.warning(`${aspect.name} ist Pflichtfeld`);
    //       }
    //     }
    //   }
    // }

    const newKauflandListing = {
      market: 'Kaufland',
      market_id,
      status: 'DRAFT',
      suggestions: { categorySuggestions, shippingGroups, warehouses },
      id_warehouse: values.id_warehouse,
      id_shipping: values.id_shipping,
      aspects: aspects,
      category: {
        id: UIProps.categoryInputValue.value,
        name: UIProps.categoryInputValue.label,
      },
    };

    dispatch(ListingActions.createOrUpdateKauflandInventory(id, newKauflandListing, method, 'edit-kaufland-listing'));
    // history.push(ModuleRoutes.KAUFLAND_LISTINGS_FN(market_id));
  };

  // Clean redux state after component unmount
  // useEffect(() => {
  //   return () => {
  //     dispatch(ListingActions.cleanUpReducer({
  //       productForEdit: {},
  //       categorySuggestions: [],
  //       shippingGroups: [],
  //       warehouses: [],
  //       isLoading: false,
  //       isLoaded: false
  //     }));
  //   };
  // }, [dispatch]);


  if (actionTracker.isSuccess && actionTracker.id === 'edit-kaufland-listing') {
    const path = ModuleRoutes.KAUFLAND_LISTINGS_FN(market_id) + lastSearch;
    return <Redirect to={path} />;
  }


  return (<>
    <EditErrorDialog show={openErrorDialog.show} onHide={closeErrorDialog} errors={openErrorDialog.errors} />

    <Card className="card-box">

      <CardHeader
        back={<BackButton link={ModuleRoutes.KAUFLAND_LISTINGS_FN(market_id) + lastSearch} />}
        title={<FormattedMessage id="PRODUCT.KAUFLAND_TITLE" />}
        id='title_kaufland_edit'
        sticky
      >

        <CardHeaderToolbar>
          <SaveButton onClick={saveClickButton} />
        </CardHeaderToolbar>

      </CardHeader>

      <CardBody>
        <FormikHandler initialValues={productForEdit} onSubmitHandler={onSubmitHandler} btnRef={btnRef}>
          <KauflandGeneral productId={id} loading={loading} />
          <KauflandPolicies market_id={market_id} loading={loading} />
          <KauflandVariant onDeletedRowChange={handleDeletedRowChange} market_id={market_id} method={method} loading={loading} />
          <KauflandCategory method={method} market_id={market_id} loading={loading} />
        </FormikHandler>
      </CardBody>

    </Card>
  </>);
}
